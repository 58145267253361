import React from 'react'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"

import NewsCarouselClient from './newsCarouselClient'

const newsCarousel = ({news, clients}) => {
    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3500,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        
        beforeChange: (index) => {
            //console.log('carousel before move - '+index)
            const theSlides = document.querySelectorAll(".slick-slide")
            const theSlide = theSlides[index+1]
            theSlide.classList.add('out-animation')
        },
        afterChange: (index) => {
            //console.log('carousel moved - '+index)
            const theSlides = document.querySelectorAll(".slick-slide")
            const theSlide = theSlides[index+1]
            theSlide.classList.add('in-animation')
        },
        
      }
      
    return (
        
        <section className="section news-carousel">
            <Slider {...settings}>
            {
                news.map( (item) => {
                    //filter for client          
                    const newsClients = clients.edges.filter((client) => {
                        return item.node.frontmatter.client_rel.includes(client.node.frontmatter.fullName)
                    })
                    console.log(newsClients)
                    return (

                        <div  key={item.node.frontmatter.title}>
                            <div className="slide-container">
                            <div className="slide-info">
                                <div className="slide-info-text">
                                    <h1>{item.node.frontmatter.title}</h1>
                                    <div className="slide-info-body" dangerouslySetInnerHTML={{__html: item.node.frontmatter.blurb}}/>
                                </div>   
                                <div className="slide-client-list">
                                {
                                  newsClients.map((newsClient) => {
                                        return(
                                            
                                                <NewsCarouselClient key={newsClient.node.frontmatter.fullName} client={newsClient} />
                                            
                                        )
                                  })  
                                }
                                </div>
                            </div>    
                            <div className="slide-img">
                                
                                <BackgroundImage
                                tag="div"
                                fluid={item.node.frontmatter.thumbnail.childImageSharp.fluid}
                                backgroundColor={`#040e18`}
                                alt={item.node.frontmatter.title}
                                />
                            </div>
                            
                            </div>
                        </div>
                    )
                })
            }
            </Slider>
        </section>
    )
}

export default newsCarousel