import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import ClientStripRandom from '../components/clientStripRandom'
import LogoStrip from '../components/logoStrip'
import Hero from '../components/hero'
import NewsCarousel from '../components/newsCarousel'
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import {FaTwitter} from 'react-icons/fa'

const IndexPage = () => {
  
  const data = useStaticQuery(graphql`
    query {
      home: markdownRemark (frontmatter: {templateKey: {eq: "home-page"}}) {
        frontmatter {
          title
          hero_message
          news_carousel
          client_carousel
          logos_boolean
          logos_carousel {
            name
            image {
              childImageSharp {
                fluid(maxWidth:500, grayscale:true, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        html
      }
      clients: allMarkdownRemark (filter: {frontmatter: {templateKey: {eq: "client"}}}) {
        edges {
          node {
            frontmatter {
              fullName
              portfolio_boolean
              spotlight_cv
              thumbnail {
                childImageSharp {
                  fluid(maxWidth:500, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            fields {
                slug
            }
          }
        }
      }
      news: allMarkdownRemark (filter: {frontmatter: {templateKey: {eq: "news"}}}) {
        edges {
          node {
            frontmatter {
              title
              blurb
              thumbnail {
                childImageSharp {
                  fluid(maxWidth:800, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              client_rel
              order
            }
            html
          }
        }
      }
    }
  `)

  //grab clients in client strip
  //const clientStrip = data.clients.edges.filter( (client) => {
  //    return data.home.frontmatter.client_carousel.indexOf(client.node.frontmatter.fullName) >= 0
  //})

//grab clients and pick six at random for strip
const clients = data.clients.edges
  //double check this sort
  clients.sort(function() { return 0.5 - Math.random() });
  const randomClients = clients.slice(0, 6)
  //grab news items in carousel
  const newsItems = data.news.edges.filter( (item) => {
    return data.home.frontmatter.news_carousel.indexOf(item.node.frontmatter.title) >= 0
  })
  //sort news items to match carousel order
  //https://gist.github.com/ecarter/1423674 - hardcoded as i couldn't get key to work
  const mapOrder = (array, order, key) => {
    array.sort((a, b) => {
      const A = a.node.frontmatter.title;
      //console.log(A)
      const B = b.node.frontmatter.title;
      //console.log(B)
      return order.indexOf(A) > order.indexOf(B) ? 1 : -1;
    });
    return array;
  };

  const orderedNewsItems = mapOrder(newsItems, data.home.frontmatter.news_carousel, 'node.frontmatter.title')

//console.log(orderedNewsItems)
  

  return (
    <Layout>
      <SEO title="Home"/>
      <NewsCarousel news={orderedNewsItems} clients={data.clients} />
      
      
      <Hero message={data.home.frontmatter.hero_message}/>

      { data.home.frontmatter.logos_boolean &&
      <LogoStrip  logos={data.home.frontmatter.logos_carousel}/>
      }
       
      <ClientStripRandom clients={randomClients} allClients={clients}/> 
      
      <section className="section columns">
      <div className="column is-half home-text">
        <div dangerouslySetInnerHTML={{__html: data.home.html}}></div>
      </div>
      <div className="column is half twitter-feed">
      <header>
      <h1><span><FaTwitter/></span> @shepperdfox</h1>
      </header>
      <TwitterTimelineEmbed
        sourceType="profile"
        screenName="shepperdfox"
        options={{height: 550}}
      />
      </div>
      </section>
    </Layout>
  )
}

export default IndexPage

