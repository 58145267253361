import React from 'react'
import {Link} from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

const newsCarouselClient = ({client}) => {
    
    let client_link = undefined
    if (client.node.frontmatter.portfolio_boolean) {
        client_link = 'profile'
    } else if (client.node.frontmatter.spotlight_cv) {
        client_link = 'spotlight'
    } else {
        client_link = false
    }
    
    return (
        <div className="slide-info-client">
        {
            client_link === 'profile' &&
                <>
                <h2>{client.node.frontmatter.fullName}</h2>
                <Link to={`/clients/${client.node.fields.slug}`}>
                <div className="image-wrapper">
                <BackgroundImage
                    tag="div"
                    fluid={client.node.frontmatter.thumbnail.childImageSharp.fluid}
                    alt={client.node.frontmatter.fullName}
                />
                </div>
                </Link>
                </>
        }
        {
            client_link === 'spotlight' &&
                <>
                <h2>{client.node.frontmatter.fullName}</h2>
                <a href={client.node.frontmatter.spotlight_cv} target="_blank">
                
                <div className="image-wrapper">
                <BackgroundImage
                    tag="div"
                    fluid={client.node.frontmatter.thumbnail.childImageSharp.fluid}
                    alt={client.node.frontmatter.fullName}
                />
                </div>
                </a>
                </>
        }
        {
            !client_link &&
            <>
            <h2>{client.node.frontmatter.fullName}</h2>
            <div className="image-wrapper">
            <BackgroundImage
                tag="div"
                fluid={client.node.frontmatter.thumbnail.childImageSharp.fluid}
                alt={client.node.frontmatter.fullName}
            />
            </div>
            </>
        }
            
        </div>
    )
}

export default newsCarouselClient