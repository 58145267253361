import React, {useState, useEffect} from 'react'
import { Link, useStaticQuery } from 'gatsby'
//import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
const ClientStripRandom = ({clients, allClients}) => {
//     const data = useStaticQuery(graphql`
//     query {
      
//       clients: allMarkdownRemark (filter: {frontmatter: {templateKey: {eq: "client"}}}) {
//         edges {
//           node {
//             frontmatter {
//               fullName
//               portfolio_boolean
//               spotlight_cv
//               thumbnail {
//                 childImageSharp {
//                   fluid(maxWidth:500) {
//                     ...GatsbyImageSharpFluid
//                   }
//                 }
//               }
//             }
//             fields {
//                 slug
//             }
//           }
//         }
//       }
//     }
      
//   `)
  
  //const theClients = clients.edges
  //double check this sort
  //clients.sort(function() { return 0.5 - Math.random() });

  const [randomClients, setRandomClients] = useState(clients)
  useEffect(() => {
    
    allClients.sort(function() { return 0.5 - Math.random() });
    const newRandom = allClients.slice(0, 6)
    setRandomClients(newRandom)
  }, []);
    return (
        <section className="client-strip section small">
        <Link to='/clients'>See Our Clients ►</Link>   
        <div className="columns is-mobile is-multiline">
            {
                randomClients.map( (randomClient) => {
                    let client_link = undefined
                    if (randomClient.node.frontmatter.portfolio_boolean) {
                        client_link = 'profile'
                    } else if (randomClient.node.frontmatter.spotlight_cv) {
                        client_link = 'spotlight'
                        console.log(randomClient.node.frontmatter.spotlight_cv)
                    } else {
                        client_link = false;
                    }
                    console.log(client_link)
                    return (
                        
                        
                        
                        <figure className="column is-half-mobile client-strip-item" key={randomClient.node.frontmatter.fullName}>
                        {
                            client_link === 'profile' &&
                            <Link to={`/clients/${randomClient.node.fields.slug}`} className="profile">
                            <BackgroundImage
                            tag="div"
                            fluid={randomClient.node.frontmatter.thumbnail.childImageSharp.fluid}
                            alt={randomClient.node.frontmatter.fullName}
                            />
                                <figcaption>
                                    {randomClient.node.frontmatter.fullName}
                                </figcaption>
                            </Link>
                        }
                        {
                            client_link === 'spotlight' &&
                            <a href={randomClient.node.frontmatter.spotlight_cv} target="_blank" className="spotlight">
                            <BackgroundImage
                            tag="div"
                            fluid={randomClient.node.frontmatter.thumbnail.childImageSharp.fluid}
                            alt={randomClient.node.frontmatter.fullName}
                            />
                                <figcaption>
                                    {randomClient.node.frontmatter.fullName}
                                   
                                </figcaption>
                            </a>
                        }
                        {
                            !client_link  &&
                            <>
                            <BackgroundImage
                            tag="div"
                            fluid={randomClient.node.frontmatter.thumbnail.childImageSharp.fluid}
                            alt={randomClient.node.frontmatter.fullName}
                            />
                                <figcaption>
                                    {randomClient.node.frontmatter.fullName}
                                </figcaption>
                            </>
                        }
                        
                        
                        </figure>
                    
                        
                        
                        
                    )
                })
            }
            </div>
        </section>
    )
}

export default ClientStripRandom