import React from 'react'
import Img from 'gatsby-image'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"

const logoStrip = ({logos}) => {
    const sliderSettings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
                dots: false
            }
            },
            {
            breakpoint: 600,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
            },
            {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
            }
        ]
    }
    return (
        <section className="section small">
            <Slider {...sliderSettings}>
            {
                logos.map( (logo) => {
                    return (
                        <div className="" key={logo.name}>
                            <Img fluid={logo.image.childImageSharp.fluid} alt={`${logo.name} logo`}/>
                        </div>
                    )
                })
            }
            </Slider>
        </section>
    )
}

export default logoStrip