import React from 'react'

const hero = (props) => {
    return (
        <section className="hero">
            <div className="hero-body">
                <div className="container">
                <h1 className="title">
                    {props.message}
                </h1>
                </div>
            </div>
            </section>
    )
}

export default hero